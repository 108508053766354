import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/workspace/public/icons/apple_logo.svg");
;
import(/* webpackMode: "eager" */ "/workspace/public/icons/google_play_logo.svg");
;
import(/* webpackMode: "eager" */ "/workspace/public/icons/ifood-logo.svg");
;
import(/* webpackMode: "eager" */ "/workspace/public/icons/popeyes-stars.svg");
;
import(/* webpackMode: "eager" */ "/workspace/public/icons/rappi-logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/hero-banner.tsx");
